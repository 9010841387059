<template>
  <div :class="`_container wrapper wrapper--${bu}`">
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="flexbox spaceevenly">
        <div class="p2 overflow">
          <h2 class="text-center">Mutaties deze week</h2>
          <MutationsTable
            :table_data="week_data.current_week"
            :table_style="bu"
          />
        </div>
        <div class="p2 overflow">
          <h2 class="text-center">Mutaties deze maand</h2>
          <MutationsTable
            :table_data="month_data.current_month"
            :table_style="bu"
          />
        </div>
      </div>
      <div class="flexbox spaceevenly">
        <div class="p2 overflow">
          <h2 class="text-center">Mutaties vorige week</h2>
          <MutationsTable
            :table_data="week_data.previous_week"
            :table_style="bu"
          />
        </div>
        <div class="p2 overflow">
          <h2 class="text-center">Mutaties vorige maand</h2>
          <MutationsTable
            :table_data="month_data.previous_month"
            :table_style="bu"
          />
        </div>
      </div>
      <div class="flexbox spaceevenly">
        <div class="p2 overflow">
          <h2 class="text-center">Mutaties 2 maanden terug</h2>
          <MutationsTable
            :table_data="month_data.penultimate_month"
            :table_style="bu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import MutationsTable from "@/components/MutationsTable.vue";
import request from "@/functions/request.js";
import moment from "moment";

export default {
  props: ["bu"],
  components: { Loading, MutationsTable },
  data: () => ({
    loading: null,
    current_week: null,
    previous_week: null,
    current_month: null,
    previous_month: null,
    penultimate_month: null,
    week_data: null,
    month_data: null,
    week_date: null,
    month_date: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.month_date = moment();
      this.week_date = moment();
      this.loading = true;
      this.current_week = this.week_date.week();
      this.previous_week = this.week_date.subtract(1, "weeks").week();
      this.current_month = this.month_date.month() + 1;
      this.previous_month = this.month_date.subtract(1, "months").month() + 1;
      this.penultimate_month = this.month_date.subtract(1, "months").month() + 1;

      request(
        `mutations/${bu}/${this.current_month}/${this.previous_month}/${this.penultimate_month}/${this.current_week}/${this.previous_week}`,
        "GET"
      ).then(({ week_data, month_data }) => {
        this.week_data = week_data;
        this.month_data = month_data;
        this.loading = false;
      });
    },
  },
};
</script>
