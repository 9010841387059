<template>
  <table class="table--perweek" :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Omschrijving</th>
        <th class="table__head--text">Aantal</th>
        <th class="table__head--text" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Waarde</th>
        <th
          v-if="table_data[0].Netto && checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
          class="table__head--text"
        >
          Netto
        </th>
        <th class="table__head--text" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Omzet</th>
        <th class="table__head--text" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">Winst</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default">{{ item.omschrijving }}</td>
        <td class="table__cell--default">{{ item.aantal }}</td>
        <td class="table__cell--default" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
          € {{ item.Waarde }}
        </td>
        <td
          v-if="table_data[0].Netto && checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
          class="table__cell--default"
        >
          € {{ item.Netto }}
        </td>
        <td class="table__cell--default" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
          € {{ item.Omzet }}
        </td>
        <td class="table__cell--default" v-if="checkroles(['management'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)">
          € {{ item.Winst }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>


export default {
  props: {
    table_data: [Array, Object],
    table_style: String,
  },

};
</script>

<style>
</style>